// i18next-extract-mark-ns-start products-wave-rapid

import { WAVE_CAPABILITIES_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWaveModelPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							For all applications of engine digital twins, the starting point is the creation of a 1D ICE model, including its instrumentation
							with control / sensor elements and fine tuning of the model to achieve the desired accuracy. The productivity focused
							toolchain and clear user interface of WAVE helps engineering teams reduce the development costs by enabling faster
							creation of accurate models as well as providing troubleshooting and diagnostics tools for any levels of engine and fuel
							complexity.
						</p>
					</Trans>
					{image && (
						<p>
							<GatsbyImage image={image} alt="WAVE model" />
						</p>
					)}
					<Trans i18nKey="content_2">
						<ul>
							<li>Import of complex engine component geometries from all common CAD platforms</li>
							<li>Efficient automated meshing of complex geometries for 1D simulations using state of the art proprietary Realis algorithms</li>
							<li>Intuitive modern GUI enabling rapid construction of complex engine models</li>
							<li>A comprehensive material database providing rapid access to properties of complex fuels and fuel blends</li>
							<li>A wide range of sensors and reporting variables putting all engine and model parameters at your fingertips</li>
							<li>Extensive control network support required by today’s engines</li>
							<li>Support for user libraries and bulk import of actuators from Excel for rapid and efficient engine instrumentation</li>
							<li>Advanced model diagnostics tools</li>
							<li>Built-in state of the art surrogate optimizer allowing rapid tuning of model parameters and delivering models matching test data with minimum effort</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_CAPABILITIES_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWaveModelPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-rapid", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE_Model.png" }) {
		...imageBreaker
	}
}
`;

